@use 'themes' as *;

@use '@nebular/theme/styles/globals' as *;

@include nb-install() {
    @include nb-theme-global();
}

;

table.stripe {
    width: 100%;
    border-collapse: collapse;
    overflow: hidden;
    margin-bottom: 20px;

    thead {
        text-align: left;

        tr {
            background: var(--color-basic-600) !important;
        }

        th,
        td {
            font-size: 0.9em;
        }
    }

    th,
    td {
        padding: 5px 10px;
        font-size: 0.9em;
    }

    tr {
        line-height: 30px;
    }

    th {
        font-weight: 800;
    }

    td {
        font-weight: 500;
    }

    tr:nth-child(even) {
        background: var(--color-basic-200);
    }

    tr:nth-child(odd) {
        background: #fff;
    }

    .actions {
        text-align: right;

        button {
            margin-right: 10px;
        }
    }
}

label {
    display: block;
}

.title-with-icon {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;

    nb-icon {
        margin-right: 10px;
    }
}

.top-actions {
    min-height: 70px;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ngx-pagination .current {
    background: var(--color-primary-500) !important;
}

.ngx-pagination {
    padding: 0;
}

.form-group .value {
    font-weight: 800;
    color: var(--color-primary-500) !important;
}

.breadcrumb {
    background-color: white;
}

nb-card-header.with-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-group .value {
    font-weight: bold;
}

nb-card-body {
    max-height: 65vh;
    overflow-y: scroll;
}

shared-edit-modal .form-error {
    font-size: 10px;
}

shared-edit-modal .status-basic {
    margin-bottom: 27px;
}

.nb-form-field-addon-basic-highlight {
    margin-bottom: 27px;
}

shared-edit-modal .nb-form-field-addon-basic, shared-edit-model .nb-form-field-addon-basic-highlight {
    margin-bottom: 27px;
}

shared-edit-modal .nb-form-field-addon-danger, shared-edit-model .nb-form-field-addon-danger-highlight {
    margin-bottom: 0px;
}

shared-edit-modal .form-group:has(.status-basic) {
    margin-bottom: 0px;
}

shared-edit-modal .form-group:has(.status-danger) {
    margin-bottom: 5px;

    .nb-button {
        margin-bottom: 0px;
    }
}
